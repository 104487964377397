// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-templates-home-pages-jsx": () => import("./../../../src/templates/HomePages.jsx" /* webpackChunkName: "component---src-templates-home-pages-jsx" */),
  "component---src-templates-redirect-jsx": () => import("./../../../src/templates/Redirect.jsx" /* webpackChunkName: "component---src-templates-redirect-jsx" */)
}

